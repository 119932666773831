import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  toggleCheckbox(event) {
    let clickedCheckbox = event.currentTarget;

    // If the checkbox was being unchecked
    if (!clickedCheckbox.checked) {
      let otherCheckboxId = clickedCheckbox.dataset.checkboxId;
      let otherCheckbox = this.checkboxTargets.find(checkbox => checkbox.id === otherCheckboxId);
      
      // If the other checkbox isn't already checked, check it
      if (!otherCheckbox.checked) {
        otherCheckbox.checked = true;
      }
    }
  }
}
